import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

import Message from '../components/Message';
import Pagination from '../components/Pagination';
import { Context } from '../components/Store';
import {
  deleteFiche,
  deleteFiches,
  editStatus,
  getFiches,
} from '../functions/fiche';
import {
  mapStatus,
  mapSubtype,
  mapType,
  statusFicheList,
  subTypeFicheValues,
  typeFicheList,
} from '../utils/constants';
import { formatDate } from '../utils/utils';

export default function Home({ location }) {
  const [state] = useContext(Context);
  const [selectedFiches, setSelectedFiches] = useState([]);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  /* Pagination */
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [fichesByPage, setFichesByPage] = useState(
    localStorage.getItem('fichesByPage') || 10
  );

  /* Search */
  useEffect(() => {
    fetchFiches();
    if (localStorage.getItem('message')) {
      setInfo(localStorage.getItem('message'));
      localStorage.removeItem('message');
    }
    if (!query.has('status')) {
      history.replace({
        search: `${query.toString()}&${new URLSearchParams({
          status: [1, 2, 3, 4],
        })}`,
      });
    }
  }, []);

  const removeFiche = async (id, numFiche) => {
    setError('');
    setInfo('');
    if (
      window.confirm(
        `Confirmez la suppression de la fiche ${numFiche}. Cette action est définitive.`
      )
    ) {
      setIsLoading(true);
      const deleted = await deleteFiche(id, state.token);
      setIsLoading(false);
      if (deleted.error) return setError(deleted.error);
      fetchFiches();
      setWarning('');
      setError('');
      setInfo(`La fiche ${numFiche} a bien été supprimée.`);
    }
  };

  const selectFiche = (id) => {
    if (!selectedFiches.includes(id)) {
      setSelectedFiches((selectedFiches) => [...selectedFiches, id]);
    } else {
      setSelectedFiches((selectedFiches) =>
        selectedFiches.filter((fiche) => fiche !== id)
      );
    }
  };

  const validateBtn = async () => {
    setInfo('');
    setError('');
    setWarning('');
    const action = document.getElementById('select-fiches').value;
    let res;
    switch (action) {
      case '1':
        res = await editStatus(selectedFiches, 4, state.token);
        if (res.some((r) => r.error))
          return setError(res.find((r) => r.error).error);
        setSelectedFiches([]);
        fetchFiches();
        setInfo(
          'Les fiches selectionnées ont été archivées. Vous pouvez les retrouver via les filtres de recherche.'
        );
        break;
      case '2':
        if (
          window.confirm(
            `Vous êtes sur le point de supprimer une ou plusieurs fiches. Confirmez la suppression (cette action est définitive) ?`
          )
        ) {
          setIsLoading(true);
          const res = await deleteFiches(selectedFiches, state.token);
          setIsLoading(false);
          if (res.some((r) => r.error))
            return setError(res.find((r) => r.error).error);
          setSelectedFiches([]);
          fetchFiches();
          setInfo(`Les fiches ont bien été supprimées.`);
        }
        break;
      default:
        break;
    }
  };
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [text, setText] = useState(() => {
    const savedText = localStorage.getItem('text');
    if (savedText) {
      return savedText;
    } else {
      return '';
    }
  });
  const updateTextFilter = (text) => {
    //console.log(text);
    setText(text);
    localStorage.setItem('text', text);
  };
  const handleChangeTextFilter = (newTextFilter) => {
    updateTextFilter(newTextFilter.target.value);
  };
  const [typeFilter, setTypeFilter] = useState(() => {
    const savedTypeFilter = localStorage.getItem('typeFilter');
    if (savedTypeFilter) {
      return JSON.parse(savedTypeFilter);
    } else {
      return [];
    }
  });
  const updateTypeFilter = (newTypeFilter) => {
    setTypeFilter(newTypeFilter);
    const type = JSON.stringify(newTypeFilter);
    if (!type.includes('Démontage') && !type.includes('Remontage')) {
      updateSubtypeFilter([]);
    }
    localStorage.setItem('typeFilter', type);
  };
  const handleChangetypeFilter = (newTypeFilter) => {
    updateTypeFilter(newTypeFilter);
  };
  const [subTypeFilter, setSubTypeFilter] = useState(() => {
    const savedSubtypeFilter = localStorage.getItem('subTypeFilter');
    if (savedSubtypeFilter) {
      return JSON.parse(savedSubtypeFilter);
    } else {
      return [];
    }
  });
  const updateSubtypeFilter = (newSubtypeFilter) => {
    setSubTypeFilter(newSubtypeFilter);
    localStorage.setItem('subTypeFilter', JSON.stringify(newSubtypeFilter));
  };
  const handleChangeSubtypeFilter = (newSubtypeFilter) => {
    updateSubtypeFilter(newSubtypeFilter);
  };

  const [statusFilter, setStatusFilter] = useState(() => {
    const savedStatusFilter = localStorage.getItem('statusFilter');
    if (savedStatusFilter) {
      return JSON.parse(savedStatusFilter);
    } else {
      return statusFicheList.filter((s) => s.value < 4);
    }
  });

  const updateStatusFilter = (newStatusFilter) => {
    setStatusFilter(newStatusFilter);
    localStorage.setItem('statusFilter', JSON.stringify(newStatusFilter));
  };
  const handleChangeStatusFilter = (newStatusFilter) => {
    updateStatusFilter(newStatusFilter);
  };

  const [fiches, setFiches] = useState([]);

  const fetchFiches = async (options = {}) => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const fetchedFiches = await getFiches(state.token, {
      limit: options.limit || fichesByPage,
      offset: options.offset || 0,
      filter: text.length > 3 ? text : options.filter,
      types: typeFilter?.map((t) => t?.value),
      subtypes: subTypeFilter?.map((t) => t?.value),
      status: statusFilter?.map((s) => s?.value),
    });
    setIsLoading(false);
    if (fetchedFiches.error) return setError(fetchedFiches.error);
    setTotalCount(fetchedFiches.totalCount);
    // Mettre à jour le tableau avec les nouvelles données
    setFiches(fetchedFiches.data ? fetchedFiches.data : []);
  };

  useEffect(() => {
    fetchFiches();
    if (localStorage.getItem('message')) {
      setInfo(localStorage.getItem('message'));
      localStorage.removeItem('message');
    }
    if (!query.has('status')) {
      history.replace({
        search: `${query.toString()}&${new URLSearchParams({
          status: [1, 2, 3, 4],
        })}`,
      });
    }
  }, [text, typeFilter, subTypeFilter, statusFilter]); // eslint-disable-line

  const searchFiches = () => {
    fetchFiches();
  };

  function CustomPagination() {
    return (
      <Pagination
        page={page}
        setPage={setPage}
        fetchFunction={fetchFiches}
        nbByPage={fichesByPage}
        setNbByPage={(nb) => {
          fetchFiches({ limit: nb });
          localStorage.setItem('fichesByPage', nb);
          return setFichesByPage(nb);
        }}
        totalCount={totalCount}
      />
    );
  }

  document.title = 'Accueil';

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1 className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8">
          Accueil
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}

        <div className="bg-viguierBleu rounded">
          <div className="flex items-center justify-between py-2 font-bold bg-viguierBleu text-white rounded-t">
            <div className="w-1/3" />
            <div className="flex w-1/3 justify-center">
              <h2 className="mr-24">Fiches d'intervention</h2>
            </div>
            <div className="flex w-1/3 justify-end">
              <Link
                to="/fiches/new"
                className="mr-4 border py-1 px-2 rounded border-white hover:bg-white hover:text-viguierBleu"
              >
                Nouvelle intervention
              </Link>
            </div>
          </div>
          <div className="bg-viguierBleu text-white justify-between flex px-2 py-2">
            <div className="flex ml-2 items-center flex-wrap">
              <form
                className="mr-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  searchFiches();
                }}
              >
                <input
                  type="search"
                  id="searchText"
                  value={text}
                  onChange={(e) => handleChangeTextFilter(e)}
                  placeholder="Rechercher une fiche d'intervention..."
                  title="Filtrer par numéro Batigest, client ou technicien"
                  className="w-full max-w-sm rounded pl-2 py-2 focus:outline-none text-black"
                />
              </form>
              <Select
                isMulti
                options={typeFicheList}
                value={typeFilter}
                onChange={handleChangetypeFilter}
                placeholder="Filtrer par type de fiche..."
                noOptionsMessage={() => ''}
                className="rounded mr-2 my-1 text-black"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: 1,
                    boxShadow: 'none',
                  }),
                }}
              />
              {(() => {
                if (
                  typeFilter.find((it) => parseInt(it.value) === 2) ||
                  typeFilter.find((it) => parseInt(it.value) === 3)
                ) {
                  return (
                    <Select
                      isMulti
                      options={subTypeFicheValues}
                      value={subTypeFilter}
                      onChange={handleChangeSubtypeFilter}
                      placeholder="Sélectionnez un type de moteur"
                      noOptionsMessage={() => ''}
                      className="rounded mr-2 my-1 text-black"
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: 1,
                          boxShadow: 'none',
                        }),
                      }}
                    />
                  );
                }
              })()}
              <span className="flex items-center flex-nowrap">
                <Select
                  isMulti
                  options={statusFicheList}
                  value={statusFilter}
                  onChange={handleChangeStatusFilter}
                  placeholder="Filtrer par statut..."
                  noOptionsMessage={() => ''}
                  className="rounded pr-2 text-black"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 1,
                      boxShadow: 'none',
                    }),
                  }}
                />
                <button
                  className="mr-4 border py-1 px-2 rounded border-white hover:bg-white hover:text-viguierBleu "
                  onClick={(e) => {
                    e.stopPropagation();
                    updateTextFilter('');
                    updateStatusFilter([]);
                    updateTypeFilter([]);
                    updateSubtypeFilter([]);
                  }}
                >
                  Reset
                </button>
              </span>
            </div>
            <CustomPagination />
          </div>
          <div className="border-4 border-white bg-viguierBleuClair mt-5 mb-5 ml-5 mr-5 rounded-md hidden lg:block">
            <table className="w-11/12 mb-4 ml-16 bg-viguierGrisClair">
              <thead className="bg-viguierBleuClair text-white h-10">
                <tr>
                  <th>
                    <CheckBoxIcon className="invisible" />
                  </th>
                  <th>N° d'intervention</th> {/* Supprimez cette ligne */}
                  <th>Client</th>
                  <th>Type de réparation</th>
                  <th>Technicien responsable</th>
                  <th>Date de prise en charge</th>
                  <th>Statut</th>
                  <th className="px-1">Options</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="9">
                      <Message message="Chargement..." type="success" />
                    </td>
                  </tr>
                ) : fiches.length > 0 ? (
                  fiches.map((fiche, i) => {
                    const id = fiche._id;
                    return (
                      <tr
                        key={id}
                        onClick={() => {
                          selectFiche(id);
                          history.push(
                            `/fiches/${fiche._id}?${query.toString()}`
                          );
                        }}
                        className={`hover:bg-viguierBleu cursor-pointer hover:text-white ${
                          i % 2 === 0 && 'bg-viguierGrisFoncé'
                        }
                                        ${
                                          selectedFiches.includes(id) &&
                                          'bg-viguierBleu text-white'
                                        }`}
                      >
                        <td>
                          {selectedFiches.includes(id) ? (
                            <CheckBoxIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                selectFiche(id);
                              }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                selectFiche(id);
                              }}
                            />
                          )}
                        </td>
                        <td>{fiche.numFiche}</td>
                        <td>
                          {fiche.contact} - {fiche.client?.enterprise}
                        </td>
                        <td>{mapType(fiche.type)}</td>
                        <td>
                          {fiche.resp ? (
                            fiche.resp.prenom && fiche.resp.nom ? (
                              `${fiche.resp.prenom} ${fiche.resp.nom}`
                            ) : (
                              fiche.resp.username
                            )
                          ) : (
                            <i>Non assigné</i>
                          )}
                        </td>
                        <td>
                          {fiche.dateDebut ? (
                            formatDate(new Date(fiche.dateDebut), true)
                          ) : (
                            <i>Non renseignée</i>
                          )}
                        </td>
                        <td>{mapStatus(fiche.status)}</td>
                        <td>
                          <button
                            className="hover:text-viguierBleuClair"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFiche(fiche._id, fiche.numFiche);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9">
                      <Message
                        message={"Aucune fiche n'a été trouvée"}
                        type="warning"
                        setError={(_) => {
                          setWarning('');
                          setText('');
                          fetchFiches({ filter: '' });
                        }}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="bg-viguierBleu text-white justify-between flex rounded-b px-2 py-2">
            <div>
              <div
                className={`${
                  selectedFiches.length === 0 ? 'hidden' : 'flex items-center'
                }`}
              >
                <label htmlFor="select-fiches" className="mr-4">
                  Action sur la sélection :
                </label>
                <select
                  name="selectable"
                  id="select-fiches"
                  className="text-black rounded"
                >
                  <option value="1">Archiver</option>
                  <option value="2">Supprimer</option>
                </select>
                <CheckCircleOutlineIcon
                  className="ml-3 hover:text-stbSecond cursor-pointer"
                  onClick={validateBtn}
                />
              </div>
            </div>
            <CustomPagination />
          </div>
        </div>
      </div>
      <div className="lg:hidden grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {fiches.map((fiche) => (
          <div
            key={fiche._id}
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            onClick={() =>
              history.push(`/fiches/${fiche._id}?${query.toString()}`)
            }
          >
            <div className="px-4 py-2 bg-viguierBleuClair text-white font-semibold uppercase">
              {fiche.numFiche}
            </div>
            <div className="py-2 px-4">
              <h1 className="text-2xl font-semibold text-gray-800">
                {fiche.contact}
              </h1>
              <p className="mt-2 text-gray-600 text-sm">
                {mapType(fiche.type)}
              </p>
              <p className="mt-2 text-gray-600 text-sm">
                Technicien responsable:{' '}
                <div>
                  {fiche.resp
                    ? fiche.resp.prenom && fiche.resp.nom
                      ? `${fiche.resp.prenom} ${fiche.resp.nom}`
                      : fiche.resp.username
                    : 'Non assigné'}
                </div>
              </p>
              <p className="mt-2 text-gray-600 text-sm">
                Date :{' '}
                {fiche.dateDebut
                  ? formatDate(new Date(fiche.dateDebut), true)
                  : 'Non renseignée'}
              </p>
              <p className="mt-2 text-gray-600 text-sm">
                Statut:{' '}
                <span className="font-bold ml-2">
                  {mapStatus(fiche.status)}
                </span>
              </p>
            </div>
            <div className="py-2 px-4 flex justify-end">
              <button
                className="text-white bg-viguierBleu hover:bg-viguierBleuFoncé font-semibold py-2 px-4 rounded-full"
                onClick={() =>
                  history.push(`/fiches/${fiche._id}?${query.toString()}`)
                }
              >
                Voir la fiche
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
